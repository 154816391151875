<template>
    <div id="wordcloud">
        <div>时间线地图</div>
        <div>
            <svg style="background-color: black" :width="width" :height="height">
                <a class="fontA" v-for="(tag, index) in tags" :key="`tag-${index}`">
                    <text :id="tag.id" :x="tag.x" :y="tag.y" :font-size="20 * (600 / (600 - tag.z))"
                        :fill-opacity="(400 + tag.z) / 600" @mousemove="listenerMove($event)"
                        @mouseout="listenerOut($event)" @click="clickToPage(tag.text)">
                        {{ tag.text }}
                    </text>
                </a>
            </svg>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: "word-cloud",
    data() {
        return {
            width: 500, 
            height: 750, 
            tagsNum: 0, 
            RADIUS: 300, 
            speedX: Math.PI / 360 / 1.5,
            speedY: Math.PI / 360 / 1.5, 
            tags: [],
            data: [
                "高卢战记",
                "美国",
                "法国",
                "罗马帝国",
                "凯撒",
                "玉米的传播",
                "水稻",
                "长平之战",
                "李白",
            ],
            timer: null,
        };
    },
    computed: {
        CX() {
            //球心x坐标
            return this.width / 2;
        },
        CY() {
            //球心y坐标
            return this.height / 2;
        },
    },
    created() {
        this.height = window.innerHeight - 300
        this.width = window.innerWidth
        this.initData();
    },
    methods: {
        // 初始化数据
        initData() {
            //初始化标签位置
            let tags = [];
            this.tagsNum = this.data.length;
            for (let i = 0; i < this.data.length; i++) {
                let tag = {};
                let k = -1 + (2 * (i + 1) - 1) / this.tagsNum;
                let a = Math.acos(k);
                let b = a * Math.sqrt(this.tagsNum * Math.PI); //计算标签相对于球心的角度
                tag.text = this.data[i];
                tag.x = this.CX + this.RADIUS * Math.sin(a) * Math.cos(b); //根据标签角度求出标签的x,y,z坐标
                tag.y = this.CY + this.RADIUS * Math.sin(a) * Math.sin(b);
                tag.z = this.RADIUS * Math.cos(a);
                tag.id = i; // 给标签添加id
                tags.push(tag);
                console.log(tag);
            }
            this.tags = tags; //让vue替我们完成视图更新
        },
        // 纵向旋转
        rotateX(angleX) {
            var cos = Math.cos(angleX);
            var sin = Math.sin(angleX);
            for (let tag of this.tags) {
                var y1 = (tag.y - this.CY) * cos - tag.z * sin + this.CY;
                var z1 = tag.z * cos + (tag.y - this.CY) * sin;
                tag.y = y1;
                tag.z = z1;
            }
        },
        // 横向旋转
        rotateY(angleY) {
            var cos = Math.cos(angleY);
            var sin = Math.sin(angleY);
            for (let tag of this.tags) {
                var x1 = (tag.x - this.CX) * cos - tag.z * sin + this.CX;
                var z1 = tag.z * cos + (tag.x - this.CX) * sin;
                tag.x = x1;
                tag.z = z1;
            }
        },
        // 运动函数
        runTags() {
            if (typeof this.timer === "number") {
                clearInterval(this.timer);
                this.timer = null;
            }
            let timer = setInterval(() => {
                this.rotateX(this.speedX);
                this.rotateY(this.speedY);
            }, 17);
            this.timer = timer;
        },
        // 监听移入事件
        listenerMove(e) {
            if (e.target.id) {
                clearInterval(this.timer);
            }
        },
        // 监听移出事件
        listenerOut(e) {
            if (e.target.id) {
                this.runTags();
            }
        },
        // 点击事件
        clickToPage(text) {
            console.log(text);
            this.$router.push('/weixinminiMap/' + text)
        },
    },
    mounted() {
        this.runTags();
    },
};
</script>
  
  
<style scoped>
#wordcloud {
    height: 100%;
    background-color: #f1e9d2;
}

.fontA {
    fill: hsl(253, 36%, 95%);
    font-weight: bold;
}

.fontA:hover {
    fill: #ffffff;
    cursor: pointer;
}
</style>
  